'use strict';

const isEscapeKeyPressed = (event) => {
  return event.key === 'Escape';
};

const isInViewport = (element) => {
  if (!element){
    return
  }
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const slideUp = function(el, duration= 500, callback) {
  el.style.transitionProperty = 'height, margin, padding';
  el.style.transitionDuration = duration + 'ms';
  el.style.boxSizing = 'border-box';
  el.style.height = el.offsetHeight + 'px';
  el.offsetHeight;
  el.style.overflow = 'hidden';
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  window.setTimeout( () => {
    el.style.display = 'none';
    el.style.removeProperty('height');
    el.style.removeProperty('padding-top');
    el.style.removeProperty('padding-bottom');
    el.style.removeProperty('margin-top');
    el.style.removeProperty('margin-bottom');
    el.style.removeProperty('overflow');
    el.style.removeProperty('transition-duration');
    el.style.removeProperty('transition-property');
    if(callback && typeof callback === 'function') {
      callback();
    }
  }, duration);
}

const slideDown = function(el, duration= 500, $display = 'block') {
//   let styleDisplay = el.style.display;
// console.log(el, styleDisplay, $display)
//   if(styleDisplay === $display) {
//     return false;
//   }

  el.style.removeProperty('display');
  let display = window.getComputedStyle(el).display;

  if(display === 'none') {
    display = $display;
  }

  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = 'hidden';
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  el.offsetHeight;
  el.style.boxSizing = 'border-box';
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + 'ms';
  el.style.height = height + 'px';
  el.style.removeProperty('padding-top');
  el.style.removeProperty('padding-bottom');
  el.style.removeProperty('margin-top');
  el.style.removeProperty('margin-bottom');
  window.setTimeout( () => {
    el.style.removeProperty('height');
    el.style.removeProperty('overflow');
    el.style.removeProperty('transition-duration');
    el.style.removeProperty('transition-property');
  }, duration);
}

const slideToggle = function(el, duration = 500) {
  if (window.getComputedStyle(el).display === 'none') {
    return slideDown(el, duration);
  } else {
    return slideUp(el, duration);
  }
}

function sendForm(form) {
  const prevForm = form.previousElementSibling;
  let replyList;
  let replyListDisplay;
  if( prevForm && prevForm.closest('.comment__list--reply') ) {
    replyList = form.previousElementSibling;
  }

  if(replyList) {
    replyListDisplay = window.getComputedStyle(replyList).display;
  }

  let comment_id = form.querySelector('.form-comment__button').getAttribute('data-id') || 0;
  let inputName = form.querySelector('input[name="first_name"]');
  let inputEmail = form.querySelector('input[name="email"]');
  let inputComment = form.querySelector('textarea[name="comment"]');
  let ratingEl = form.querySelector('input[name="rating"]:checked');
  let ratingValue = ratingEl ? ratingEl.value : '';
  let validRating = true;
  const btnReply = document.querySelector('.js-comment-reply[data-id="' + comment_id + '"]');

  if( form.querySelector('input[name="rating"]') ) {
    if(ratingValue === '') {
      validRating = false;
      form.querySelector('.form-comment__error--rating').style.display = 'block';
    } else {
      validRating = true;
      form.querySelector('.form-comment__error--rating').style.display = 'none';
    }
  }

  let formData = new FormData();
  formData.append('action', 'add_comment');
  formData.append('post_id', form.querySelector('#post_id').value);
  formData.append('comment_id', comment_id);
  formData.append('name', inputName.value);
  formData.append('email', inputEmail.value);
  formData.append('comment', inputComment.value);
  formData.append('rating', ratingValue);

  let validName = validateInput(inputName, {
    regExp: /\D/,
    error: 'nextElement'
  });
  let validEmail = validateInput(inputEmail, {
    regExp: /.+@.+\..+/,
    error: 'nextElement'
  });
  let validComment = validateInput(inputComment, {
    error: 'nextElement'
  });

  validateInputEvent(inputName, {
    regExp: /\D/,
    error: 'nextElement'
  });
  validateInputEvent(inputEmail, {
    regExp: /.+@.+\..+/,
    error: 'nextElement'
  });
  validateInputEvent(inputComment, {
    error: 'nextElement'
  });

  if(validName && validEmail && validComment && validRating) {
    fetch(ajaxUrl, {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if(data.status == 'fail') {
        return false;
      }

      if(data.status == 'done') {
        inputName.value = '';
        inputEmail.value = '';
        inputComment.value = '';

        popupSuccess();

        if(btnReply) {
          btnReply.removeAttribute('disabled');

          if(replyList && replyListDisplay === 'none') {
            slideUp(form.parentElement, 500, () => {
              form.remove();
            });
          } else if(replyList && replyListDisplay !== 'none') {
            slideUp(form, 500, () => {
              form.remove();
            });
          } else {
            slideUp(form.parentElement, 500, () => {
              form.parentElement.remove();
            });
          }
        }
      }
    });
  }
}

function validateInput(input, options) {
  const errorsEl = {
    nextElement: 'nextElementSibling',
    prevElement: 'previousElementSibling',
  }
  let value = input.value;
  let valid = false;

  if(options?.regExp) {
    valid = options.regExp.test(value);
  } else {
    valid = value.length > 0;
  }

  if(valid) {
    input.classList.remove('error');
    input[ errorsEl[options.error] ].style.display = 'none';
  } else {
    input.classList.add('error');
    input[ errorsEl[options.error] ].style.display = 'block';
  }

  return valid;
}

function validateInputEvent(input, options) {
  input.addEventListener('input', () => {
    validateInput(input, options);
  });
}

function popupSuccess() {
  if(!document.querySelector('.pop-up--success')) {
    const popupSuccessTemplate = document.querySelector('#pop-up-seccess');
    let popup = popupSuccessTemplate.content.cloneNode(true);
    document.body.append(popup);
  }

  let popupSuccess = document.querySelector('.pop-up--success');
  popupSuccess.classList.add('opened');

  setTimeout(() => {
    popupSuccess.classList.remove('opened');
  }, 5000);
}

export { isEscapeKeyPressed, isInViewport, slideDown, slideUp, slideToggle, sendForm, validateInput, validateInputEvent, popupSuccess };
