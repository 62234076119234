'use strict';

import { slideDown, slideUp, sendForm } from './utils.js';

document.addEventListener('DOMContentLoaded', function () {
  const templateForm = document.querySelector('#form-reply');
  const reply = document.querySelectorAll('.js-comment-reply');

  if(reply) {
    reply.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.target.setAttribute('disabled', '');
        const id = e.target.getAttribute('data-id');
        let form = templateForm.content.cloneNode(true);
        let formButtons = form.querySelectorAll('.button');
        let replyContainer = e.target.parentElement.nextElementSibling;
        let offset = 150;
        let offsetPosition;

        formButtons.forEach((btn) => {
          btn.setAttribute('data-id', id);
        });

        if(replyContainer) {
          let replyContainerDisplay = window.getComputedStyle(replyContainer).display;
          replyContainer.append(form);

          if(replyContainerDisplay === 'none') {
            replyContainer.querySelector('form').removeAttribute('style');
            slideDown(replyContainer);
          } else {
            slideDown(replyContainer.querySelector('form'), 500, 'grid');
          }

          offsetPosition = replyContainer.querySelector('form').getBoundingClientRect().top;
        } else {
          let elContainer = document.createElement('div');
          elContainer.className = 'comment-card__replies';
          elContainer.style.display = 'none';
          elContainer.append(form);
          elContainer.querySelector('form').removeAttribute('style');
          e.target.parentElement.parentElement.append(elContainer);
          slideDown(elContainer);
          offsetPosition = elContainer.querySelector('form').getBoundingClientRect().top;
        }

        window.scrollBy({
          top: offsetPosition - offset,
          behavior: 'smooth'
        });
      });
    });
  }

  const replyShow = document.querySelectorAll('.js-comment-show-reply');
  if(replyShow) {
    replyShow.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.target.classList.add('hidden');
        let replyContainer = e.target.parentElement.nextElementSibling;
        let replyList = replyContainer.querySelector('.comment__list--reply');
        replyList.classList.add('opened');

        if(replyContainer.children.length > 1) {
          slideDown(replyList)
        } else {
          replyList.style.display = 'block';
          slideDown(replyContainer);
        }
      });
    });
  }

  const comment = document.querySelector('.comment');
  if(comment) {
    comment.addEventListener('click', (e) => {
      if( e.target.closest('.form-comment__cancel') ) {
        const id = e.target.getAttribute('data-id');
        const btnReply = document.querySelector('.js-comment-reply[data-id="' + id + '"]');
        btnReply.removeAttribute('disabled');
        const form = e.target.parentElement.parentElement;
        const replyList = form.previousElementSibling;
        let replyListDisplay;
        if(replyList) {
          replyListDisplay = window.getComputedStyle(replyList).display;
        }

        if(replyList && replyListDisplay === 'none') {
          slideUp(form.parentElement, 500, () => {
            form.remove();
          });
        } else if(replyList && replyListDisplay !== 'none') {
          slideUp(form, 500, () => {
            form.remove();
          });
        } else {
          slideUp(form.parentElement, 500, () => {
            form.parentElement.remove();
          });
        }
      }

      if( e.target.closest('.form-comment__button') ) {
        e.preventDefault();
        let form = e.target.parentElement;

        if(form.tagName.toLowerCase() !== 'form') {
          form = e.target.parentElement.parentElement;
        }

        sendForm(form);
      }
    });
  }

  let rating = document.querySelectorAll('input[name="rating"]');
  if(rating) {
    rating.forEach((item) => {
      item.addEventListener('change', () => {
        document.querySelector('.form-comment__error--rating').style.display = 'none';
      });
    });
  }

  document.body.addEventListener('click', (e) => {
    if( e.target.closest('.pop-up__close--success') ) {
      document.querySelector('.pop-up--success').classList.remove('opened');
    }
  });
});