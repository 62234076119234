'use strict';

document.addEventListener('DOMContentLoaded', function () {
  const stickyBrandCard = document.querySelector('.brand-card--sticky');


  const stickyPopUpToggle = (popUp) => {
    let currentScrollPosition = document.body.getBoundingClientRect();
    let viewportHeight = document.documentElement.clientHeight;
    let positionDiff = viewportHeight + currentScrollPosition.top;

    if (positionDiff < 0 && !popUp.classList.contains('visible')){
      popUp.classList.add('visible');
    } else if(positionDiff > 0 && popUp.classList.contains('visible')) {
      popUp.classList.remove('visible');
    }
  }

  if (stickyBrandCard){
    document.addEventListener('scroll', function (){
      stickyPopUpToggle(stickyBrandCard);
    });
  }

});
