'use strict';

document.addEventListener('DOMContentLoaded', function () {
  const anchorsMenu = document.querySelector('.anchor-menu-js-toggle');

  const toggleAnchorMenu = () => {
    anchorsMenu.classList.toggle('opened');
  };

  if (anchorsMenu) {
    const anchorsMenuBtn = anchorsMenu.querySelector('.anchor-menu__btn');

    anchorsMenuBtn.addEventListener('click', toggleAnchorMenu);
  }
});
