'use strict';

document.addEventListener('DOMContentLoaded', function () {
  const button = document.querySelector('button.shared-links__url');
  if(button) {
    button.addEventListener('click', (e) => {
      const link = e.target.getAttribute('data-url');
      let inputTmp = document.createElement('input');
      inputTmp.value = link;
      inputTmp.setAttribute('type', 'text')
      e.target.append(inputTmp);
      let input = e.target.querySelector('input');
      input.focus();
      input.select();
      let result = document.execCommand('copy');
      if(result) {
        e.target.classList.add('tooltip');
        setTimeout(() => {
          e.target.classList.remove('tooltip');
        }, 2000);
      }
      input.remove();
    });
  }

  const links = document.querySelectorAll('a.shared-links__url');
  if(links) {
    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        let url = e.target.getAttribute('href');
        if(url === '#') {
          e.preventDefault();
        }
      });
    });
  }
});