'use strict';
import { isEscapeKeyPressed } from './utils.js';

document.addEventListener('DOMContentLoaded', function () {

  const burgerBlock = document.querySelector('.burger-js');
  const dropdownMenus = document.querySelectorAll('.dropdown-container');

  const burgerInit = function () {
    let burgerBtn = burgerBlock.querySelector('.burger__icon');
    let burgerOverlay = burgerBlock.querySelector('.burger__overflow');
    let burgerClose = burgerBlock.querySelector('.burger__close');
    burgerBtn.addEventListener('click', burgerToggle);
    burgerOverlay.addEventListener('click', burgerToggle);
    burgerClose.addEventListener('click', burgerToggle);
  };

  const burgerToggle = () => {
    burgerBlock.classList.toggle('opened');
    document.body.classList.toggle('page-body--no-scroll');

    if (burgerBlock.classList.contains('opened')) {
      document.addEventListener('keydown', burgerEscClose);
    }

    dropdownMenus.forEach(dropdownMenu => {
      dropdownMenu.classList.remove('opened');
    });
  };

  const burgerEscClose = (event) => {
    if (isEscapeKeyPressed(event)) {
      burgerBlock.classList.remove('opened');
      document.body.classList.remove('page-body--no-scroll');

      dropdownMenus.forEach(dropdownMenu => {
        dropdownMenu.classList.remove('opened');
      });
    }
  };

  if (burgerBlock) {
    burgerInit();
  }

  const itemMenuButtons = document.querySelectorAll('.js-menu-button');

  const getNextSibling = function (elem, selector) {
    let sibling = elem.nextElementSibling;

    if (!selector) return sibling;

    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };

  itemMenuButtons.forEach(itemMenuButton => {
    const itemDropdownMenu = getNextSibling(itemMenuButton, '.dropdown-container');

    const openDropdown = () => {
      itemDropdownMenu.classList.add('opened');
    };

    itemMenuButton.addEventListener('click', openDropdown);
  });

  const itemMenuButtonsPrev = document.querySelectorAll('.js-menu-button-prev');

  itemMenuButtonsPrev.forEach(itemMenuButtonPrev => {
    const activeDropdownMenu = itemMenuButtonPrev.closest('.dropdown-container');

    const closeDropdown = () => {
      activeDropdownMenu.classList.remove('opened');
    };

    itemMenuButtonPrev.addEventListener('click', closeDropdown);
  });
});
