'use strict';

document.addEventListener('DOMContentLoaded', function () {
  const btnHref = document.querySelectorAll('.button--href');
  if(btnHref) {
    btnHref.forEach((button) => {
      button.addEventListener('click', (e) => {
        const link = e.target.getAttribute('data-href');
        window.location.href = link;
      });
    });
  }
});