'use strict';
import { isEscapeKeyPressed } from './utils.js';

document.addEventListener('DOMContentLoaded', function () {


  function refferalButtonAction(event) {

    event.preventDefault();
    const currentItem = event.currentTarget;
    const dataRef = currentItem.getAttribute("data-ref");
    const formRedirect = document.createElement("form");
    formRedirect.target = "_blank";
    formRedirect.method = "POST";
    formRedirect.action = "";
    const formRedirectInput = document.createElement("input");
    formRedirectInput.type = "hidden";
    formRedirectInput.name = "ref-redirect-data";
    formRedirectInput.value = dataRef;
    formRedirect.appendChild(formRedirectInput);
    document.body.appendChild(formRedirect);
    formRedirect.submit();
    formRedirect.remove();

    refButtonPopUp();
  }

  function redirectLogic() {
    const redirectBlock = document.querySelector(".redirect-ref-js");
    if (redirectBlock) {
      window.location.href = redirectBlock.getAttribute("data-ref-link");
    }
  }

  function refButtonPopUp(event) {
    if(event) {
      event.preventDefault();
    }

    const popUpBlock = document.querySelector('.pop-up--ref-cards-js');

    if (!popUpBlock) {
      return false;
    }

    popUpBlock.classList.add('opened');
    document.body.classList.toggle('page-body--no-scroll');

    let closeIcon = popUpBlock.querySelector('.pop-up__close');

    closeIcon.addEventListener('click', function (event) {
      popUpClose(popUpBlock);
    });

    document.addEventListener('keydown', function (event) {
      if (isEscapeKeyPressed(event)) {
        popUpClose(popUpBlock);
      }
    });

  }

  function popUpClose(popUpBlock) {

    popUpBlock.classList.remove('opened');
    document.body.classList.remove('page-body--no-scroll');
  }


  const refBtns = document.querySelectorAll('.referral-link-btn-js');

  if (refBtns){
    [...refBtns].forEach((refBtn) => {
      if (refBtn.classList.contains('empty-ref-pop-up-js')) {
        refBtn.addEventListener('click', refButtonPopUp);
      } else {
        refBtn.addEventListener('click', refferalButtonAction);
      }
    });

    redirectLogic();
  }
});
